import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query{
      logo: file(relativePath:{eq:"story-duel-logo.png"}) {
        childImageSharp {
          fluid(maxWidth:250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mainImage: file(relativePath: { eq: "coffee-dessert.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 540) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <div className="logo">
        <Img fluid={data.logo.childImageSharp.fluid} alt="Story Duel" />
      </div>
      <div class="headerImg">
        <Img fluid={data.mainImage.childImageSharp.fluid} alt="" className="headerImg__img" />
      </div>
      <div class="content">
        <h1>We’re Taking a Break</h1>
        <p>Story Duel is taking a hiatus. Thank you for all the stories and great writing duels.</p>
        <p>Still got the writing bug? <br/>Try <a href="https://www.storywars.net/">StoryWars.net</a></p>
        <p className="bold">A big thank you to everyone that has contributed. <br/>Keep on writing!</p>
      </div>
    </Layout>
  )
}

export default IndexPage
